<template>
    <div class="filters-middle-item">
        <h3>Προκαταβολή</h3>
        <div class="form-payment">
            <input type="number" id="payment" v-model="chrimatodotisi.prokatavoli" :placeholder="chrimatodotisi.prokatavoli"/>
            <div>€</div>
        </div>
        <p>Εισάγετε το ποσό που μπορείτε να καταβάλλετε μετρητοίς κατά την αγορά του αυτοκινήτου σας</p>
    </div>
    <div class="filters-middle-item">
        <h3>Διάρκεια χρηματοδότησης</h3>
        <Range @slider_value="getDoseis" :value="chrimatodotisi.doseis" :min="0" :max="84" :step="1" />
        <p>Επιλέξτε τη χρονική διάρκεια της χρηματοδότησης σε μήνες</p>
    </div>

    <!-- Make -->
    <div class="filters-middle-item">
        <div :class="box.make ? 'filters-middle-item-drop-active' : ''" class="filters-middle-item-drop" @click="this.box.make = !this.box.make">
            <h3>Μάρκα</h3>
            <Arrow />
        </div>
        <div v-if="box.make" class="filters-middle-content filters-middle-content-expand" >
            <div v-for="(make, key) in data.make" :key="key">
                <input type="checkbox" :id="('make-'+make)" v-model="filters.make" :value="make" />
                <label class="filters-middle-content-with-img" :for="('make-'+make)">
                    <img width="32" height="32" class="filters-middle-img" :src="'/images/brands/'+make.replace(/\s|[_]/g, '-').toLowerCase()+'.png'" :alt="make+' - View model'">
                    {{ make }}
                </label>
            </div>
        </div>
    </div>

    <!-- Model -->
    <div v-if="filters.make.length > 0" class="filters-middle-item">
        <div :class="box.model ? 'filters-middle-item-drop-active' : ''" class="filters-middle-item-drop" @click="this.box.model = !this.box.model">
            <h3>Μοντέλο</h3>
            <Arrow />
        </div>
        <div v-if="box.model" class="filters-middle-split" >
            <div v-for="(models, key) in convertModels" :key="key">
                <h4>{{ models.make }}</h4>
                <div v-for="model in models.models" :key="model"  class="filters-middle-split-content">
                    <input type="checkbox" :id="('model-'+model)" :checked="filters.model[models.make] ? filters.model[models.make].includes(model) : false" @click="setModelFilter(model, models.make)"/>
                    <label :for="('model-'+model)">{{ model }}</label>
                </div>
            </div>
 
        </div>
    </div>

    <!-- Regiyear -->
    <div class="filters-middle-item">
        <div :class="box.regiyear ? 'filters-middle-item-drop-active' : ''" class="filters-middle-item-drop" @click="this.box.regiyear = !this.box.regiyear">
            <h3>Χρονολογία</h3>
            <Arrow />
        </div>
        <div v-if="box.regiyear" class="filters-middle-item-drop">
            <div>
                <label :for="('regiyear-from')">Από</label>
                <div class="filters-middle-item-drop-select">
                    <select v-model="filters.regiyear['from']">
                        <option value="0" selected disabled>Επιλογή</option>
                        <option v-for="(regiyear) in regiyearRange" :value="regiyear">{{ regiyear }}</option>
                    </select>
                </div>
            </div>
            <div>
                <label :for="('regiyear-from')">Έως</label>
                <div class="filters-middle-item-drop-select">
                    <select v-model="filters.regiyear['to']">
                        <option value="0" selected disabled>Επιλογή</option>
                        <option v-for="(regiyear) in regiyearRange.filter((item) => { if ( filters.regiyear['from'] ) { return item > filters.regiyear['from'] } else { return item } })" :value="regiyear">{{ regiyear }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <!-- Price -->
    <div class="filters-middle-item">        
        <div :class="box.price ? 'filters-middle-item-drop-active' : ''" class="filters-middle-item-drop" @click="this.box.price = !this.box.price">
            <h3>Τιμή από/έως</h3>
            <Arrow />
        </div>
        <div v-if="box.price" class="filters-middle-price" >
            <div class="filters-item-checkbox">
                <div v-for="(item, key) in saleArray" :key="key">
                    <input type="checkbox" :id="('sale-price-'+item.id)" v-model="filters[item.id]" :value="item.value" />
                    <label :for="('sale-price-'+item.id)">{{ item.name }}</label>
                </div>
            </div>

            <Range mutli @slider_value="getPrice" :value="filters.price" :step="1000" :min="data.price.min" :max="data.price.max" />
        </div>
    </div>

    <!-- Condition -->
    <div class="filters-middle-item">
        <div :class="box.condition ? 'filters-middle-item-drop-active' : ''" class="filters-middle-item-drop" @click="this.box.condition = !this.box.condition">
            <h3>Κατάσταση</h3>
            <Arrow />
        </div>
        <div v-if="box.condition" class="filters-middle-content" >
            <div v-for="(condition, key) in conditionArray" :key="key">
                <input type="checkbox" :id="('condition-'+condition)" v-model="filters.condition" :value="condition"/>
                <label :for="('condition-'+condition)">{{ condition }}</label>
            </div>
        </div>
    </div>

    <!-- Category -->
    <div class="filters-middle-item">
        <div :class="box.category ? 'filters-middle-item-drop-active' : ''" class="filters-middle-item-drop" @click="this.box.category = !this.box.category">
            <h3>Κατηγορία</h3>
            <Arrow />
        </div>
        <div v-if="box.category" class="filters-middle-content" >
            <div v-for="(system, key) in data.category" :key="key">
                <input type="checkbox" :id="('system-'+system)" v-model="filters.category" :value="system"/>
                <label :for="('system-'+system)">{{ system }}</label>
            </div>
        </div>
    </div>

    <!-- Color -->
    <div class="filters-middle-item">
        <div :class="box.color ? 'filters-middle-item-drop-active' : ''" class="filters-middle-item-drop" @click="this.box.color = !this.box.color">
            <h3>Χρώμα</h3>
            <Arrow />
        </div>
        <div v-if="box.color" class="filters-middle-content" >
            <div v-for="(color, key) in data.color" :key="key">
                <input type="checkbox" :id="('color-'+color)" v-model="filters.color" :value="color"/>
                <label :for="('color-'+color)">{{ color }}</label>
            </div>
        </div>
    </div>

    <!-- Engine -->
    <div class="filters-middle-item">
        <div :class="box.engine ? 'filters-middle-item-drop-active' : ''" class="filters-middle-item-drop" @click="this.box.engine = !this.box.engine">
            <h3>Κυβικά</h3>
            <Arrow />
        </div>
        <div v-if="box.engine" class="filters-middle-item-drop">
            <div>
                <label :for="('engine-from')">Από</label>
                <div class="filters-middle-item-drop-select">
                    <select v-model="filters.engine['from']">
                        <option value="0" selected disabled>Επιλογή</option>
                        <option v-for="(engine) in engineRange" :value="engine">{{ engine }}</option>
                    </select>
                </div>
            </div>
            <div>
                <label :for="('engine-from')">Έως</label>
                <div class="filters-middle-item-drop-select">
                    <select v-model="filters.engine['to']">
                        <option value="0" selected disabled>Επιλογή</option>
                        <option v-for="(engine) in engineRange.filter((item) => { if ( filters.engine['from'] ) { return item > filters.engine['from'] } else { return item } })" :value="engine">{{ engine }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <!-- Drive -->
    <div class="filters-middle-item">
        <div :class="box.drive ? 'filters-middle-item-drop-active' : ''" class="filters-middle-item-drop" @click="this.box.drive = !this.box.drive">
            <h3>Σασμάν</h3>
            <Arrow />
        </div>
        <div v-if="box.drive" class="filters-middle-content" >
            <div v-for="(drive, key) in driveArray" :key="key">
                <input type="checkbox" :id="('drive-'+drive)" v-model="filters.drive" :value="drive"/>
                <label :for="('drive-'+drive)">{{ drive }}</label>
            </div>
        </div>
    </div>

    <!-- Transmission -->
    <div class="filters-middle-item">
        <div :class="box.transmission ? 'filters-middle-item-drop-active' : ''" class="filters-middle-item-drop" @click="this.box.transmission = !this.box.transmission">
            <h3>Μετάδοση</h3>
            <Arrow />
        </div>
        <div v-if="box.transmission" class="filters-middle-content" >
            <div v-for="(transmission, key) in transmissionArray" :key="key">
                <input type="checkbox" :id="('transmission-'+transmission.name)" v-model="filters.transmission" :value="transmission.value"/>
                <label :for="('transmission-'+transmission.name)">{{ transmission.name }}</label>
            </div>
        </div>
    </div>

    <!-- Mileage -->
    <div class="filters-middle-item">
        <div :class="box.mileage ? 'filters-middle-item-drop-active' : ''" class="filters-middle-item-drop" @click="this.box.mileage = !this.box.mileage">
            <h3>Χιλιόμετρα από/έως</h3>
            <Arrow />
        </div>
        <div v-if="box.mileage" class="filters-middle-price" >
            <Range mutli @slider_value="getMileage" :value="filters.mileage" :step="500" :min="data.mileage.min" :max="data.mileage.max" />
        </div>
    </div>


    <!-- Fuel Type -->
    <div class="filters-middle-item">
        <div :class="box.fuel_type ? 'filters-middle-item-drop-active' : ''" class="filters-middle-item-drop" @click="this.box.fuel_type = !this.box.fuel_type">
            <h3>Καύσιμο</h3>
            <Arrow />
        </div>
        <div v-if="box.fuel_type" class="filters-middle-content" >
            <div v-for="(fuel_type, key) in data.fuel_type" :key="key">
                <input type="checkbox" :id="('fuel_type-'+fuel_type)" v-model="filters.fuel_type" :value="fuel_type"/>
                <label :for="('fuel_type-'+fuel_type)">{{ fuel_type }}</label>
            </div>
        </div>
    </div>

    <!-- Features -->
    <div class="filters-middle-item">
        <div :class="box.features ? 'filters-middle-item-drop-active' : ''" class="filters-middle-item-drop" @click="this.box.features = !this.box.features">
            <h3>Εξοπλισμός</h3>
            <Arrow />
        </div>
        <div v-if="box.features" class="filters-middle-content" >
            <div v-for="(features, key) in data.features" :key="key">
                <input type="checkbox" :id="('features-'+features)" v-model="filters.features" :value="features"/>
                <label :for="('features-'+features)">{{ features }}</label>
            </div>
        </div>
    </div>
</template>

<script>
    import { Arrow, Funnel, Close } from "../../Icons/Icons";
    import Range from "../Slider/Range.vue";
    import { reactive } from "vue";
    import { router } from "@inertiajs/core";

    export default {
        components: {
            Range, Arrow, Funnel, Close
        },
        props: {
            data: Object,
            active: Object,
            clearFilters: Number,
            triggerFilters: Number,
            mobile: Boolean,
            perpage: String,
            sorting: String,
        },
        setup(props, { slots, attrs }) {
            const chrimatodotisi = reactive({
                doseis: props.active['doseis'] ? props.active['doseis'] : 48,
                prokatavoli: props.active['prokatavoli'] ? props.active['prokatavoli'] : 2500,
            });

            const saleArray = [
                { name: 'Προσφορές', value: 1, id: 'sale_price' },
                // { name: 'Black Friday', value: true, id: 'offer' },
            ];

            const range = (start, end, step) => { return Array.from({ length: ( end + 1 - start ) / step }, (_, i) => start + (i * step)) }
            const mileageRange = [9000, 120000];
            const engineRange = [900, 1000, 1200, 1500, 2000, 2500, 3000, 3500, 4000, 5000];
            const regiyearRange = range(1992, 2023, 1);
            const driveArray = ['Αυτόματο', 'Χειροκίνητο'];
            const conditionArray = ['Καινούργιο', 'Μεταχειρισμένο'];
            const transmissionArray = [{name: 'Πισωκίνητο', value: 'Πισωκίνητο'}, {name: 'Προσθιοκίνητο', value: 'Προσθιοκίνητο'}, {name: '4WD/AWD', value: '4WD/AWD'}];

            const filters = reactive({
                make: props.active['make'] ? props.active['make'] : [],
                model: props.active['model'] ? props.active['model'] : {},
                category: props.active['category'] ? props.active['category'] : [],
                color: props.active['color'] ? props.active['color'] : [],
                fuel_type: props.active['fuel_type'] ? props.active['fuel_type'] : [],
                price: props.active['price'] ? [ 
                    props.active['price'].from ? props.active['price'].from : props.data.price.min,
                    props.active['price'].to ? props.active['price'].to : props.data.price.max
                ] : [ props.data.price.min, props.data.price.max ],
                mileage: props.active['mileage'] ? [ 
                    props.active['mileage'].from ? props.active['mileage'].from : props.data.mileage.min,
                    props.active['mileage'].to ? props.active['mileage'].to : props.data.mileage.max
                ] : [ props.data.mileage.min, props.data.mileage.max ],
                engine: props.active['engine'] ? props.active['engine'] : {},
                transmission: props.active['transmission'] ? props.active['transmission'] : [],
                drive: props.active['drive'] ? props.active['drive'] : [],
                features: props.active['features'] ? props.active['features'] : [],
                regiyear: props.active['regiyear'] ? props.active['regiyear'] : {},
                condition: props.active['condition'] ? props.active['condition'] : [],
                sale_price: props.active['sale_price'] ? props.active['sale_price'] : [],
                offer: props.active['offer'] ? props.active['offer'] : [],
            }); 

            const box = reactive({
                make: filters.make.length > 0 ? true : false,
                model: Object.values(filters.model).length > 0 ? true : false,
                category: filters.category.length > 0 ? true : false,
                color: filters.color.length > 0 ? true : false,
                fuel_type: filters.fuel_type.length > 0 ? true : false,
                price: filters.price[0] > props.data.price.min || filters.price[1] < props.data.price.max || filters.sale_price.length > 0 || filters.offer.length > 0 ? true : false,
                mileage: filters.mileage[0] > props.data.mileage.min || filters.mileage[1] < props.data.mileage.max ? true : false,
                engine: Object.values(filters.engine).length > 0 ? true : false,
                regiyear: Object.values(filters.regiyear).length > 0 ? true : false,
                transmission: filters.transmission.length > 0 ? true : false,
                drive: filters.drive.length > 0 ? true : false,
                features: filters.features.length > 0 ? true : false,
                condition: filters.condition.length > 0 ? true : false,
            });

            return { chrimatodotisi, saleArray, filters, box, transmissionArray, driveArray, conditionArray, mileageRange, engineRange, regiyearRange }
        },
        data() {
            return {
                setModels: {},
                debounceFilters: null,
                chrimatodotisiTrigger: false,
            }
        },
        inject: ['updateSliderFilter','updateChrimatodotisiFilter','updateGridFilter'],
        watch: {
            filters: { handler() {
                const timer = 50;
                // Debounce effect
                clearTimeout(this.debounceFilters);
                this.debounceFilters = setTimeout(() => { this.getFilters() }, timer)
            }, deep: true },
            updateGridFilter: { handler() { 
                this.setFilters()
            }, deep: true },
            updateChrimatodotisiFilter: { handler() { 
                if ( !this.active['doseis'] ) { this.chrimatodotisi.doseis = 48; }
                if ( !this.active['prokatavoli'] ) { this.chrimatodotisi.prokatavoli = 2500; }
            }, deep: true },
            updateSliderFilter: { handler(item) {
                const keys = Reflect.ownKeys(this.filters[item.filter]);
                // delete the object
                delete this.filters[item.filter][keys[item.id]];

                // this.setFilters();
            }, deep: true },
            triggerFilters: { handler() { this.setFilters() }, deep: true },
            chrimatodotisi: { handler() { 
                const timer = 600;
                // Debounce effect
                clearTimeout(this.debounceFilters);
                this.debounceFilters = setTimeout(() => { this.getFilters() }, timer)
            }, deep: true },
            perpage: { handler() { this.setFilters() }, deep: true },
            sorting: { handler() { this.setFilters() }, deep: true },
            clearFilters: { handler() { this.resetFilters() }, deep: true },
        },
        computed: {
            convertModels() {
                // Get Make
                const activeMake = this.filters.make.map((make) => make);
                // Get All Models
                const allModels = Object.entries(this.data.model).map((model) => {
                    return {make: model[0], models: model[1].map((e) => e)}
                })
                // Filter the arrays
                return allModels.filter((model) => activeMake.includes(model.make))
            },
        },
        methods: {
            getDoseis(value) {
                this.chrimatodotisi.doseis = value;
            },
            getPrice(value) {
                this.filters.price = value;
            },
            getMileage(value) {
                this.filters.mileage = value;
            },
            // activateFilters(filter, value) {
            //     this.filters[filter].push(value)
            //     this.getFilters();
            // },
            setModelFilter(model, make) {
                // An exw idi to array gia auth thn make
                if (this.filters.model[make]) {
                    // An uparxei eidi epilegmeno to montelo, to vgazw apo to array
                    if(this.filters.model[make].includes(model)){
                        const index = this.filters.model[make].indexOf(model);
                        if (index !== -1) {
                            this.filters.model[make].splice(index, 1);
                        }
                    } else {
                        // An den to exw, Kanw push to mew model
                        this.filters.model[make].push(model)
                    }

                } else {
                    // Ftiaxnw gia 1h fora to array gia auth thn make
                    this.filters.model[make] = [model]
                }

                this.setModels = this.filters.model;
            },
            getFilters() { if ( !this.mobile ) { this.setFilters() } },
            setFilters() {
                let allQuery = {};
                this.$global.filtersLoading = true;
                // Get the model make
                const modelQuery = Object.entries(this.setModels).map(model => model[0]);
                // Remove the make from the model
                modelQuery.map((model, index) => {
                    if ( !this.filters.make.includes(model) ) {
                        // Get the keys form the object
                        const keys = Reflect.ownKeys(this.setModels);
                        // delete the object
                        return delete this.setModels[keys[index]];
                    }
                });

                if ( this.perpage !== '24' ) { allQuery.per_page = this.perpage }
                if ( this.sorting !== 'noorder' ) { allQuery.sorting = this.sorting }

                if ( this.chrimatodotisi.doseis !== 48 || this.chrimatodotisi.prokatavoli !== 2500 ) { 
                    allQuery.doseis = this.chrimatodotisi.doseis;
                    allQuery.prokatavoli = this.chrimatodotisi.prokatavoli;
                }

                if ( this.filters.price[0] > this.data.price.min && this.filters.price[1] < this.data.price.max ) {
                    allQuery.price = { from: this.filters.price[0], to: this.filters.price[1] };
                } else {
                    if ( this.filters.price[0] > this.data.price.min ) { allQuery.price = { from: this.filters.price[0] } };
                    if ( this.filters.price[1] < this.data.price.max ) { allQuery.price = { to: this.filters.price[1] } };
                }

                if ( this.filters.mileage[0] > this.data.mileage.min && this.filters.mileage[1] < this.data.mileage.max ) {
                    allQuery.mileage = { from: this.filters.mileage[0], to: this.filters.mileage[1] };
                } else {
                    if ( this.filters.mileage[0] > this.data.mileage.min ) { allQuery.mileage = { from: this.filters.mileage[0] } };
                    if ( this.filters.mileage[1] < this.data.mileage.max ) { allQuery.mileage = { to: this.filters.mileage[1] } };
                }

                allQuery.sale_price = this.filters.sale_price;
                allQuery.offer = this.filters.offer;
                allQuery.make = this.filters.make;
                allQuery.engine = this.filters.engine;
                allQuery.regiyear = this.filters.regiyear;
                allQuery.transmission = this.filters.transmission;
                allQuery.drive = this.filters.drive;
                allQuery.model = this.setModels;
                allQuery.category = this.filters.category;
                allQuery.color = this.filters.color;
                allQuery.fuel_type = this.filters.fuel_type;
                allQuery.features = this.filters.features;
                allQuery.condition = this.filters.condition;

                router.get(window.location.pathname, allQuery, {
                    onSuccess: () => this.$global.filtersLoading = false,
                    replace: true 
                })
            },
            resetFilters() {
                this.filters.sale_price = [],
                this.filters.offer = [],
                this.filters.make = [],
                this.filters.model = {},
                this.filters.category = [],
                this.filters.color = [],
                this.filters.fuel_type = [],
                this.filters.price = {},
                this.filters.mileage = {},
                this.filters.engine = {},
                this.filters.transmission = [],
                this.filters.drive = [],
                this.filters.features = [],
                this.filters.condition = [],
                this.filters.regiyear = {},
                this.chrimatodotisi.doseis = 48;
                this.chrimatodotisi.prokatavoli = 2500;
                this.chrimatodotisiTrigger = false;
                window.history.pushState({}, null, window.location.origin+window.location.pathname);
            },
        },
        mounted() {
            this.setModels = this.filters.model;
        }

    }
</script>